import React from "react"
import styled from "styled-components"
import FacebookIcon from "../assets/Facebook.svg"
import InstagramIcon from "../assets/Instagram.svg"
import TwitterIcon from "../assets/Twitter.svg"
import { useViewport } from "../hooks/useViewport"

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  color: white;
  background-color: #212027;
  justify-content: space-between;
  align-items: center;

  .text {
    font-family: "Lato";
    font-size: 24px;
    font-style: italic;
    margin-left: 90px;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 130px;
    align-items: center;

    svg {
      margin-left: 41px;
    }
  }
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  color: white;
  background-color: #212027;
  justify-content: space-around;
  align-items: center;
`

const IconContainer = () => (
  <>
    <a href="https://www.instagram.com/thearmstrongproj/">
      <InstagramIcon style={{ fill: "white" }} />
    </a>
    <a href="https://twitter.com/ArmstrongProj">
      <TwitterIcon />
    </a>
    <a href="https://www.facebook.com/The-Armstrong-Project-109701624232105/">
      <FacebookIcon />
    </a>
  </>
)

const Footer = () => {
  const { width } = useViewport()
  const breakpoint = 768

  return width <= breakpoint ? (
    <MobileContainer>
      <IconContainer />
    </MobileContainer>
  ) : (
    <FooterContainer>
      <div className="text">
        Timeless values to impact future generations in a useable and meaningful
        way
      </div>
      <div className="icon-container">
        <IconContainer />
      </div>
    </FooterContainer>
  )
}

export default Footer
