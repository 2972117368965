import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import MobileNavigation from "./mobileNavigation"
import NavIcon from "../../assets/NavIcon.svg"
import { useViewport } from "../../hooks/useViewport"
import Button from "../../components/common/StandardButton"

const NavContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 36px;
  align-items: ${props => (props.stacked ? "flex-start" : "center")};
  width: 100%;
  height: ${props => (props.stacked ? "130px" : "90px")};
  position: fixed;
  background-color: white;
  z-index: 1000;
  top: 0;
  svg {
    margin-left: 72px;
  }
`
const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Lato";
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;

  a {
    margin: 0 0 0 50px;
    text-decoration: none;
    color: black;
  }

  .selected {
    color: #c84836;
  }

  .bio {
    font-weight: bold;
    font-size: 14px;
  }

  @media (max-width: 1360px) {
    font-size: 16px;

    .bio {
      font-size: 12px;
    }
  }

  .standard-button {
    margin-left: 20px !important;
    padding-top: 0 !important;
    width: 120px !important;
  }
`
const Line = styled.div`
  width: 69vw;
  height: 1px;
  background-color: #e6e6e7;
  margin-top: 20px;
  margin-bottom: 20px;
`

const StackedButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SignupModal = styled.div`
  background: rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  > div:not(.bg) {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    position: relative;
    width: 320px;
    z-index: 3;

    h3 {
      font-family: "Chapaza";
      font-size: 24px;
      margin: 0;
      span {
        color: #c84836;
      }
    }

    form {
      padding: 10px;
    }

    input {
      box-sizing: border-box;
      margin: 10px 0;
      padding: 5px 0 5px 5px;
      width: 100%;
    }

    .standard-button div {
      opacity: 1;
    }
  }

  .bg {
    background: #c84836;
    height: 234px;
    margin: 60px 0 0 60px;
    position: absolute;
    width: 320px;
    z-index: 2;
  }
`

const NavLink = ({ id, onClickHandler, route, text }) => (
  <Link activeClassName="selected" id={id} to={route} onClick={onClickHandler}>
    {text}
  </Link>
)

const MainButtonContainer = ({ onClickHandler }) => (
  <ButtonContainer>
    <Link activeClassName="selected" to="/about/" onClick={onClickHandler}>
      About
    </Link>
    <Link id="bio" to="/bio/about" onClick={onClickHandler}>
      Senator Armstrong's Biography
    </Link>
    <Link
      activeClassName="selected"
      to="/ellen-armstrong"
      onClick={onClickHandler}
    >
      Ellen Armstrong
    </Link>
    <Link activeClassName="selected" to="/collection" onClick={onClickHandler}>
      The Collection
    </Link>
    <Link activeClassName="selected" to="/award" onClick={onClickHandler}>
      William L. Armstrong Award
    </Link>
  </ButtonContainer>
)

const BioSecondaryButtonContainer = () => (
  <ButtonContainer
    style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "15px" }}
    className="bio"
  >
    <Link activeClassName="selected" to="/bio/about/">
      About Senator Bill Armstrong
    </Link>
    <Link activeClassName="selected" to="/bio/broadcaster/">
      Broadcaster
    </Link>
    <Link activeClassName="selected" to="/bio/businessman/">
      Businessman
    </Link>
    <Link activeClassName="selected" to="/bio/statesman/">
      Statesman
    </Link>
    <Link activeClassName="selected" to="/bio/christian/">
      Christian Activist
    </Link>
    <Link activeClassName="selected" to="/bio/educator/">
      Educator
    </Link>
  </ButtonContainer>
)

const Signup = ({ closeModal, style, signupResponse, handleSubmit }) => (
  <SignupModal style={{ ...style }} onClick={closeModal}>
    <div>
      <h3>
        Subscribe <span>for updates</span>
      </h3>
      <form
        name="contact"
        id="signup-form"
        method="POST"
        onSubmit={handleSubmit}
      >
        <div style={{ display: "flex" }}>
          <div>
            <input
              required
              type="text"
              name="firstname"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              required
              type="text"
              name="lastname"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div>
          <input required type="email" name="email" placeholder="Email" />
        </div>
        <p style={{ textAlign: "center" }}>
          <button
            style={{
              background: "none",
              border: "1px solid #C84836",
              color: "#C84836",
              padding: "10px 20px",
            }}
            type="submit"
          >
            Sign Up
          </button>
        </p>
        <p>{signupResponse}</p>
      </form>
      {/* <div className="bg-sender">
      <div className="sender-form-field" data-sender-form-id="kpr98pfoee0oldim0ac"></div>
    </div> */}
    </div>
    <div className="bg"></div>
  </SignupModal>
)

const Navigation = ({ stacked = false, isSignup = false }) => {
  const { width } = useViewport()
  const breakpoint = 768
  const [stackedContainer, showStackedContainer] = useState(stacked)
  const [signup, toggleSignup] = useState(isSignup)
  const [signupResponse, setSignupResponse] = useState("")

  const onClickHandler = ({ target: { id } }) => {
    if (id.indexOf("bio") !== -1) {
      showStackedContainer(true)
    } else {
      showStackedContainer(false)
    }
    if (id.indexOf("sign-up") !== -1) {
      console.log(signup)
      toggleSignup(!signup)
    }
  }

  const closeModal = e => {
    if (e.target.className.indexOf("navigation__SignupModal") !== -1) {
      toggleSignup(!signup)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("signup-form")
    let formData = new FormData(myForm)
    formData = new URLSearchParams(formData)

    if (formData.get("firstname") == "" || formData.get("email") == "") {
      setSignupResponse("All fields required")
    } else {
      fetch("/.netlify/functions/sender", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formData.get("email"),
          firstname: formData.get("firstname"),
          lastname: formData.get("lastname"),
        }),
      })
        .then(() => {
          setSignupResponse("Thanks for subscribing, we'll be in touch!")
        })
        .catch(error => {
          setSignupResponse("Error signing up.")
        })

      setTimeout(() => {
        toggleSignup(false)
        setSignupResponse("")
      }, 2500)
    }
  }

  return width <= breakpoint ? (
    <>
      <MobileNavigation />
      <Signup
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        signupResponse={signupResponse}
        style={{
          display: signup ? "flex" : "none",
        }}
      />
    </>
  ) : (
    <NavContainer stacked={stackedContainer}>
      <Link to="/">
        <NavIcon style={{ marginRight: "74px" }} />
      </Link>
      {stackedContainer ? (
        <StackedButtonContainer>
          <MainButtonContainer onClickHandler={onClickHandler} />
          <Button
            cursor={"pointer"}
            onClick={() => onClickHandler({ target: { id: "sign-up" } })}
            text={"Sign Up"}
            color="#C84836"
            styles={{
              margin: "0 20px 0 auto",
              width: "100px",
              float: "right",
              paddingTop: 0,
            }}
          />
          <Line />
          <BioSecondaryButtonContainer />
        </StackedButtonContainer>
      ) : (
        <>
          <MainButtonContainer onClickHandler={onClickHandler} />
          <Button
            cursor={"pointer"}
            onClick={() => onClickHandler({ target: { id: "sign-up" } })}
            text={"Sign Up"}
            color="#C84836"
            styles={{
              margin: "0 20px 0 auto",
              width: "100px",
              float: "right",
              paddingTop: 0,
            }}
          />
        </>
      )}
      <Signup
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        signupResponse={signupResponse}
        style={{
          display: signup ? "flex" : "none",
        }}
      />
    </NavContainer>
  )
}

export default Navigation
